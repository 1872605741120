import ChinaIcon from "assets/Mobile/icons/china_flag.png";
import EnglishIcon from "assets/Mobile/icons/english_flag.png";
import MenuLanguage from "assets/Mobile/icons/menuLanguage.webp";
export interface ItemLanguageType {
  id: string;
  value: string;
  label: string;
  picture: string;
}
export const languageOptions: ItemLanguageType[] = [
  { id: "en", value: "en", label: "English", picture: EnglishIcon },
  { id: "zh", value: "zh", label: "Chinese", picture: ChinaIcon },
  { id: "ms", value: "ms", label: "Malaysia", picture: MenuLanguage },
];
