/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button, Image, Modal } from "antd";
import { ReactComponent as RightIcon } from "assets/temporary/icons/right_icon.svg";
import { ReactComponent as WalletIcon } from "assets/temporary/icons/wallet.svg";
import { Dispatch, SetStateAction } from "react";
import { PlayerBankType } from "types/bank";

import { ModalDepositStyle } from "./index.style";

interface Props {
  isOpenModalTransaction: boolean;
  setIsModalTransction: Dispatch<SetStateAction<boolean>>;
  pictureBanking?: string;
  bank_name?: string;
  onSubmit?(): void;
  amount: number | null;
  isWithdraw?: boolean;
  detailPlayerBank?: PlayerBankType;
  type?: number;
  gatewayPicture?: string;
  gateway?: string;
}

export const ModalTransaction = (props: Props) => {
  const {
    isOpenModalTransaction,
    setIsModalTransction,
    pictureBanking,
    bank_name,
    onSubmit,
    amount,
    isWithdraw,
    detailPlayerBank,
    type,
    gatewayPicture,
    gateway,
  } = props;
  return (
    <Modal
      open={isOpenModalTransaction}
      onCancel={() => setIsModalTransction(false)}
      centered
      footer={null}
      css={ModalDepositStyle.index}
    >
      <div>
        <div css={ModalDepositStyle.backgroundHeader}>
          <p css={ModalDepositStyle.title}>Transaction confirmation</p>
          <div css={ModalDepositStyle.content}>
            <div
              css={ModalDepositStyle.form}
              style={{
                height: "140px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <WalletIcon height={28} width={28} />

                <span
                  style={{
                    paddingTop: "4px",
                    fontWeight: 700,
                    fontSize: "24px",
                    marginTop: "10px",
                  }}
                >
                  {amount} MYR
                </span>

                <div
                  style={{
                    marginLeft: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      paddingTop: "4px",
                    }}
                  >
                    {isWithdraw
                      ? "Withdraw money to your bank account"
                      : "Deposit money into your account"}
                  </span>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {!isWithdraw && <p>Receiving bank</p>}
              <div
                css={ModalDepositStyle.selectCurrency}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "10px",
                  height: isWithdraw ? "60px" : "44px",
                }}
              >
                {isWithdraw ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      css={css`
                        font-weight: 500;
                        font-size: 15px;
                      `}
                    >
                      {detailPlayerBank?.bank_name}
                    </p>
                    <p
                      css={css`
                        color: #95959d;
                        font-size: 12px;
                      `}
                    >
                      {detailPlayerBank?.bank_account_no}
                    </p>
                  </div>
                ) : type === 1 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Image src={pictureBanking} height={28} width={28} />
                    <p
                      style={{
                        marginLeft: "8px",
                      }}
                    >
                      {bank_name}
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Image src={gatewayPicture} height={28} width={28} />
                    <p
                      style={{
                        marginLeft: "8px",
                      }}
                    >
                      {gateway}
                    </p>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    Change
                  </p>
                  <RightIcon />
                </div>
              </div>
            </div>

            <Button
              css={ModalDepositStyle.button}
              style={{
                color: "white",
              }}
              onClick={onSubmit}
            >
              OK
            </Button>
          </div>
        </div>
        <div css={ModalDepositStyle.backgroundBottom} />
      </div>
    </Modal>
  );
};
