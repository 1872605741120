/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Image, Modal } from "antd";
import { ReactComponent as CheckedIcon } from "assets/temporary/icons/checked.svg";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useEffect } from "react";
import { selectUsername } from "store/auth";
import { selectBanks } from "store/bank";
import { getListBank } from "store/bank/bank.thunk";
import { BankType } from "types/bank";

import { ModalSelectBankStyle } from "./index.style";

interface Props {
  visible: boolean;
  onClose: () => void;
  currencyId?: string;
  onSelectItem: (val: BankType) => void;
  setBank?: any;
}

export const ModalSelectBank = (props: Props) => {
  const { visible, onClose, currencyId, onSelectItem, setBank } = props;
  const dispatch = useAppDispatch();
  const banks = useAppSelector(selectBanks);
  const username = useAppSelector(selectUsername);

  useEffect(() => {
    if (!username) return;
    if (banks.length > 0) {
      setBank(banks[0]);
      return;
    }
    dispatch(getListBank(username));
  }, [banks.length, username]);

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      centered
      css={ModalSelectBankStyle.index}
      footer={null}
    >
      <div
        style={{
          padding: "20px",
        }}
      >
        <div
          style={{
            textAlign: "center",
            marginBottom: "25px",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Choose a bank
        </div>

        <div
          style={{
            marginBottom: "20px",
          }}
        >
          {banks.map((bank: BankType, index: number) => (
            <div
              css={ModalSelectBankStyle.selectBank}
              key={bank.bank_id}
              style={{
                borderRadius:
                  index === 0 ? "10px 10px 0px 0px" : "0px 0px 10px 10px",
                borderBottom: "1px solid #F5F5F5",
                height: "100%",
              }}
              onClick={() => {
                onSelectItem(bank);
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Image
                  src={bank.picture}
                  height={38}
                  width={38}
                  css={css`
                    height: 100%;
                    width: 100%;
                    border-radius: 5px;
                  `}
                />
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    margin-left: 8px;
                    font-size: 14px;
                  `}
                >
                  <p>{bank.bank_name}</p>
                </div>
              </div>

              {bank.bank_id === currencyId && <CheckedIcon />}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};
