import IconApp from "assets/Common/icons/iplay_logo 2.png";
import { Helmet } from "react-helmet";
type Props = {
  title: string | undefined;
};

function HelmetCustom(props: Props) {
  const { title = "" } = props;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title + " - iPLAY88"}</title>
      <meta
        name="description"
        content="iPLAY88 is a trusted online casino in Malaysia where you can play Slots, Sports Betting, Baccarat, Blackjack, Roulette and more online casino games. Register an account and start playing. Welcome Bonus and Daily Bonus ready to claim"
      />
      <link rel="icon" type="image/png" href={IconApp} />
    </Helmet>
  );
}

export default HelmetCustom;
