/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button, Modal } from "antd";
import BankCard from "assets/temporary/icons/bank_card.png";
import CloseIcon from "assets/temporary/icons/Close.png";
import { ReactComponent as RightArrowSvg } from "assets/temporary/icons/right_arrow.svg";
import { ReactComponent as CardBGItemSvg } from "assets/temporary/images/card_bg_item.svg";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useVerify } from "hooks/useVerify";
import { useEffect, useState } from "react";
import { selectUsername } from "store/auth";
import { selectPlayerBanks } from "store/bank";
import { getListPlayerBank } from "store/bank/bank.thunk";
import { selectOpenModal, setOpenModal } from "store/modal";
import { theme } from "styles/theme";
import { PlayerBankType } from "types/bank";

import { BankStyle } from "./index.style";
import ModalBankMapping from "./ModalBankMapping";

function Bank() {
  useVerify();

  const dispatch = useAppDispatch();

  const playerBanks = useAppSelector(selectPlayerBanks);
  const username = useAppSelector(selectUsername);
  const openModal = useAppSelector(selectOpenModal);

  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    if (!username) return;
    if (playerBanks.length > 0) return;
    dispatch(getListPlayerBank(username));
  }, [username]);

  const onClose = () => dispatch(setOpenModal(""));
  return (
    <Modal
      centered
      open={openModal.includes("bank")}
      closable={false}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
      css={BankStyle.modalContainer}
    >
      <div css={BankStyle.container}>
        <div css={BankStyle.header}>
          <div css={css({ width: 24, height: 24 })} />
          <span>{"Bank"}</span>
          <Button onClick={onClose} css={BankStyle.buttonClose}>
            <img src={CloseIcon} alt="" />
          </Button>
        </div>
        <div css={BankStyle.content}>
          <div onClick={() => setOpenForm(true)} css={BankStyle.create_box}>
            <div>
              <span>Add a bank account</span>
              <RightArrowSvg width={14} height={14} stroke={theme.colors.red} />
            </div>
            <div css={BankStyle.bankCard}>
              <img src={BankCard} alt="" />
            </div>
          </div>
          <div css={BankStyle.listBank}>
            {playerBanks?.length > 0 && (
              <span css={BankStyle.labelList}>
                List of banks added ({playerBanks?.length})
              </span>
            )}
            {playerBanks.map((item: PlayerBankType, index: number) => {
              const color = theme.colors.bank[(index % 10) + 1];
              return (
                <div
                  style={{ marginTop: !index ? 8 : 0 }}
                  key={`item-bank-player-${index}`}
                  css={BankStyle.itemBank}
                >
                  <div css={BankStyle.itemBankInfo}>
                    <div>
                      <span>{item.bank_name}</span>
                      <span>{item.bank_account_name}</span>
                    </div>
                    <div>
                      <span>****{" " + item.bank_account_no.slice(-4)}</span>
                    </div>
                  </div>
                  <div css={BankStyle.itemBg}>
                    <CardBGItemSvg fill={color} width={"100%"}></CardBGItemSvg>
                  </div>
                </div>
              );
            })}
          </div>
          {!playerBanks?.length && (
            <div css={BankStyle.center}>
              <span>You haven't added a bank yet</span>
            </div>
          )}
        </div>
      </div>
      <ModalBankMapping visible={openForm} onClose={() => setOpenForm(false)} />
    </Modal>
  );
}

export default Bank;
