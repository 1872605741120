/** @jsxImportSource @emotion/react */
import "react-phone-number-input/style.css";

import { SyncOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, notification, Popover } from "antd";
import { AuthApi } from "api/auth";
import { RegisterFormType, RequestOTPType } from "api/auth/type";
import backgroundLogin from "assets/temporary/images/list_bg_2.jpg";
import CheckboxCustom from "components/Common/CheckboxCustom";
import InputCustom from "components/Common/InputCustom";
import InputPassword from "components/Common/InputPassword";
import InputPhoneNumber from "components/Common/InputPhoneNumber";
import { ModalCustom } from "components/Common/ModalCustom";
import { PATH } from "contants/path";
import { REGEX_EMAIL, REGEX_PASSWORD, REGEX_USERNAME } from "contants/regex";
import { ResponseCode } from "contants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useCountDownTime } from "hooks/useCountDownTime";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Link, useNavigate } from "react-router-dom";
import { selectIsLoading } from "store/auth";
import { registerMemberDetail } from "store/auth/auth.thunk";
import { selectOpenModal, setOpenModal } from "store/modal";
import { theme } from "styles/theme";
import { StatusNotification } from "types";
import * as yup from "yup";

import { LogoAuth } from "../LogoAuth";
import { SignupModalStyle } from "./index.style";

interface RegisterForm extends RegisterFormType {
  approve: boolean;
}

interface props {
  isCode: string | null;
}
export const SignupModal = ({ isCode }: props) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { t } = useTranslation(["login", "placeholder", "button"]);

  const openModal = useAppSelector(selectOpenModal);
  const isLoading = useAppSelector(selectIsLoading);

  const [isFocusFullname, setIsFocusFullname] = useState<boolean>();
  const [isTriggerTimer, setIsTriggerTimer] = useState<boolean>(false);

  const [countDown, setTime, time] = useCountDownTime(300, isTriggerTimer);

  const schema = yup
    .object({
      username: yup
        .string()
        .trim()
        .matches(REGEX_USERNAME, t("validate:UsernameValid"))
        .required(t("validate:UsernameRequired")),
      email: yup
        .string()
        .matches(REGEX_EMAIL, t("validate:EmailRequired"))
        .email()
        .required(t("validate:EmailRequired")),
      fullname: yup.string().trim().required(t("validate:FiledRequired")),
      mobile: yup
        .string()
        .trim()
        .required(t("validate:MobileRequired"))
        .test("len", t("validate:PhoneRequired"), (val) =>
          isValidPhoneNumber(`${val}`)
        ),
      password: yup
        .string()
        .trim()
        .matches(REGEX_PASSWORD, t("validate:PasswordValid"))
        .required(t("validate:PasswordValid")),
      confirm_password: yup
        .string()
        .trim()
        .matches(REGEX_PASSWORD, t("validate:PasswordConfirm"))
        .required(t("validate:PasswordConfirm")),
      referral: yup.string().trim(),
      otp: yup
        .string()
        .trim()
        .required(t("validate:OTPValid"))
        .min(6, t("validate:OTPValid"))
        .max(6, t("validate:OTPValid")),
      approve: yup
        .boolean()
        .required()
        .oneOf([true], t("validate:ConditionsCheck")),
    })
    .required();

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    formState: { errors, isValid },
    setValue,
  } = useForm<RegisterForm>({
    resolver: yupResolver<any>(schema),
    mode: "all",
  });
  const isMobile = watch("mobile");
  const isUsername = watch("username");

  useEffect(() => {
    if (isCode) {
      setValue("referral", isCode);
    }
  }, []);

  const [disableInput, setDisableInput] = useState<boolean>(false);

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (status: StatusNotification, message: string) => {
    api[status]({
      message,
      description: "",
    });
  };

  useEffect(() => {
    if ((time as number) > 0) return;

    (setTime as Dispatch<SetStateAction<number>>)(300);
    setDisableInput(false);
    setIsTriggerTimer(false);
  }, [time]);

  const onSubmit = async (data: RegisterForm) => {
    const dataForm = {
      ...data,
      mobile: data.mobile && data.mobile.replace("+", ""),
      username: data.username.toLocaleLowerCase(),
    };
    const res: any = await dispatch(
      registerMemberDetail({ payload: dataForm })
    );

    const { status, msg } = res.payload;

    if (status !== ResponseCode.SUCCESS) {
      openNotification("error", msg);
      return;
    }

    openNotification("success", msg);
    dispatch(setOpenModal(""));
  };

  const handleCloseModal = () => {
    dispatch(setOpenModal(""));
  };

  const openT_C = () => {
    navigate("/");
    dispatch(setOpenModal(""));
  };

  const getOtpCode = async () => {
    const data = getValues(["username", "mobile"]);

    const dataRequest: RequestOTPType = {
      username: data[0].toLocaleLowerCase(),
      mobile: data[1].replace("+", ""),
      type: 1,
    };

    const res = await AuthApi.requestOTP(dataRequest);
    const resData = res.data;

    openNotification(
      resData.status === ResponseCode.SUCCESS ? "success" : "error",
      resData.status === 901 ? "Invalid Mobile Number" : resData.msg
    );

    if (resData.status === ResponseCode.SUCCESS) {
      setDisableInput(true);
      setIsTriggerTimer(true);
    } else {
      setDisableInput(false);
    }
  };

  const checkButtonGetOtp = () => {
    if (isTriggerTimer) return true;

    if (!isMobile || (isMobile && !isValidPhoneNumber(isMobile))) {
      return true;
    }
    if (!isUsername || (isUsername && errors.username?.message)) {
      return true;
    }
    return false;
  };

  return (
    <ModalCustom
      isOpenModal={openModal.includes("signup")}
      handleCloseModal={handleCloseModal}
      titleModal={t("Register")}
      headerBackground={backgroundLogin}
      heightBackground={75}
      bodyModal={
        <div style={{ overflow: "auto" }}>
          <LogoAuth />

          <Form
            css={SignupModalStyle.formContainer}
            onSubmitCapture={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <InputCustom
              disabled={isLoading || disableInput}
              placeholder={t("placeholder:Username")}
              name={"username"}
              control={control}
              errors={errors.username?.message}
              margin_bottom={"8px"}
              validate_status={errors.username ? "error" : "success"}
              maxLength={16}
            />
            <InputPassword
              disabled={isLoading}
              placeholder={t("placeholder:Password")}
              name={"password"}
              control={control}
              margin_bottom="8px"
              error={errors.password?.message}
              validate_status={errors.password ? "error" : "success"}
              maxLength={15}
            />
            <InputPassword
              disabled={isLoading}
              placeholder={t("placeholder:ConfirmPassword")}
              name={"confirm_password"}
              control={control}
              margin_bottom="8px"
              autoComplete="new-password"
              error={errors.confirm_password?.message}
              validate_status={errors.confirm_password ? "error" : "success"}
              maxLength={15}
            />

            <InputCustom
              disabled={isLoading || disableInput}
              placeholder={t("placeholder:Email")}
              name={"email"}
              control={control}
              errors={errors.email?.message}
              margin_bottom={"8px"}
              validate_status={errors.email ? "error" : "success"}
            />

            <InputCustom
              disabled={isLoading}
              placeholder={t("placeholder:FullName")}
              name={"fullname"}
              margin_bottom="8px"
              control={control}
              errors={
                errors.fullname?.message
                  ? errors.fullname?.message
                  : isFocusFullname
                  ? t("validate:UsernameMatch")
                  : ""
              }
              validate_status={
                isFocusFullname || errors.fullname ? "error" : "success"
              }
              onClick={() => setIsFocusFullname(true)}
              onBlur={() => setIsFocusFullname(false)}
              onKeyDown={(e) => {
                const key = e.key;

                const regex = new RegExp("^[a-zA-Z\\s]+$");

                if (!regex.test(key) && key !== "Enter") {
                  e.preventDefault();
                }
              }}
            />

            <InputPhoneNumber
              disabled={isLoading || disableInput}
              name={"mobile"}
              control={control}
              margin_bottom="8px"
              error={errors.mobile?.message}
              validate_status={errors.mobile?.type ? "error" : "success"}
              prefix={
                <div
                  css={css`
                    padding-right: 5px;
                    button {
                      border: 0;
                      background-color: ${theme.colors.red};
                      height: 36px;
                      padding: 0 8px;
                      margin-right: 4px;

                      &:disabled {
                        background-color: ${theme.colors.gray[200]};
                      }

                      span {
                        line-height: unset !important;
                        color: ${theme.colors.white};
                      }
                    }
                  `}
                >
                  <Button
                    onClick={getOtpCode}
                    disabled={checkButtonGetOtp()}
                    css={css`
                      width: 70px;
                    `}
                  >
                    {!disableInput ? (
                      t("button:GetOTP")
                    ) : (
                      <span
                        css={css`
                          padding-top: 2px;
                        `}
                      >
                        {countDown ? (countDown as string) : "05:00"}
                      </span>
                    )}
                  </Button>
                </div>
              }
            />

            <Form.Item
              style={{ marginBottom: "10px" }}
              help={errors.otp?.message}
              validateStatus={errors.otp ? "error" : "success"}
            >
              <Controller
                name={"otp"}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div css={SignupModalStyle.optCode}>
                      <input
                        placeholder={t("placeholder:OTPcode")}
                        value={value || ""}
                        onChange={onChange}
                        maxLength={6}
                        onKeyDown={(e) => {
                          const key = e.key;

                          if (key === "Backspace") return;

                          const regex = new RegExp("^[0-9]+$");

                          if (!regex.test(key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  );
                }}
              />
            </Form.Item>

            <Popover
              placement="topRight"
              content={t("validate:Popover")}
              trigger="hover"
            >
              <InputCustom
                disabled={isLoading || !!isCode}
                placeholder={t("placeholder:ReferralCode")}
                name={"referral"}
                margin_bottom="8px"
                control={control}
                errors={errors.referral?.message}
                validate_status={errors.referral ? "error" : "success"}
              />
            </Popover>

            <CheckboxCustom
              disabled={isLoading}
              name={"approve"}
              control={control}
              errors={errors.approve?.message}
              css={SignupModalStyle.approve}
              validate_status={errors.approve ? "error" : "success"}
            >
              {t("AccessGame")}{" "}
              <Link to={PATH.TermsAndConditions} onClick={openT_C}>
                {t("Terms")}
              </Link>{" "}
              {t("Iplay")}.
            </CheckboxCustom>

            <button
              css={[
                SignupModalStyle.buttonSubmit,
                isValid && SignupModalStyle.buttonActive,
              ]}
              type="submit"
              disabled={isLoading || !isValid}
            >
              {isLoading ? <SyncOutlined spin /> : t("button:Register")}
            </button>
            <span css={SignupModalStyle.login}>
              {t("Already")}
              <span
                onClick={() => dispatch(setOpenModal("signin"))}
                style={{
                  marginLeft: "4px",
                }}
              >
                {t("LOGIN")}
              </span>
            </span>
          </Form>
          {contextHolder}
        </div>
      }
    />
  );
};
