import { css } from "@emotion/react";
import BackgroundDeposit from "assets/temporary/images/banner_deposit.png";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const ModalDepositStyle = {
  index: () => css`
    width: 480px !important;

    .ant-modal-content {
      padding: 0;
      background-color: #f5f5f5;
      border-radius: 10px;

      .ant-modal-close-x {
        svg {
          color: white;
          height: 24px;
          width: 24px;
        }
      }
    }
  `,
  backgroundHeader: () => css`
    background-image: url(${BackgroundDeposit});
    height: 150px;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  `,
  backgroundBottom: () => css`
    background-color: #f5f5f5;
    height: 300px;
    width: 100%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  `,

  title: () => css`
    color: white;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    flex-grow: 1;
    margin-left: 46px;
    padding: 24px 0;
  `,

  form: () => css`
    height: 260px;
    width: 100%;
    background-color: white;
    margin-bottom: 25px;
    border-radius: 10px;
    padding: 18px;
  `,
  button: () => css`
    width: 100%;
    height: 44px;
    border-radius: 8px;
    background-color: ${theme.colors.red};
    margin-top: 40px;
    color: white;

    :hover {
      transform: scale(1.05);
      background-color: ${theme.colors.red};
      border: ${theme.colors.red};
      color: white !important;
    }
  `,

  currency: () => css`
    grid-column: 4 / 6;
    height: 44px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #1c1c1e;
    font-weight: 500;
    cursor: pointer;
  `,

  depositType: () => css`
    height: 44px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #636369;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 20px;
  `,

  selectCurrency: () => css`
    padding: 16px;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    cursor: pointer;
  `,

  content: () => css`
    width: 416px;
    position: relative;
    z-index: 99;
    margin: 25px auto;

    ${media.sm} {
      width: 100%;
    }
  `,

  modal: () => css`
    width: 480px !important;

    .ant-modal-content {
      padding: 0;
      background-color: #f5f5f5;
      border-radius: 10px;
    }
  `,
  buttonClose: () => css`
    width: 24px;
    height: 24px;
    border: 0px;
    background-color: transparent;
    display: flex;
    align-items: center;
    margin-right: 22px;
    z-index: 9999;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
      z-index: 9999;
    }
  `,
  header: () => css`
    display: flex;
    padding-top: 20px;
  `,

  uploadFile: {
    input: css`
      flex: 1;
      /* border: 1px solid rgba(0, 0, 0, 0.1); */
      border-radius: 10px;
      margin-bottom: 20px;
    `,
  },
};
