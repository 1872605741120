/** @jsxImportSource @emotion/react */
import { ReactComponent as AffiliateSVG } from "assets/Common/icons/affiliate.svg";
import { ReactComponent as AffiliateActiveSvg } from "assets/Common/icons/affiliateActive.svg";
import { ReactComponent as BrandSVG } from "assets/Common/icons/brand.svg";
import { ReactComponent as BrandActiveSVG } from "assets/Common/icons/brandActive.svg";
import { ReactComponent as HomeSVG } from "assets/Common/icons/home.svg";
import { ReactComponent as HomeActiveSvg } from "assets/Common/icons/homeActive.svg";
import { ReactComponent as ProfileSvg } from "assets/Common/icons/profile.svg";
import { ReactComponent as ProfileActiveSvg } from "assets/Common/icons/profileActive.svg";
import { ReactComponent as PromosActiveSvg } from "assets/Common/icons/promoActive.svg";
import { ReactComponent as PromosSvg } from "assets/Common/icons/promos.svg";
import { ReactComponent as ContactUsSVG } from "assets/Mobile/icons/contact-us.svg";
import { ReactComponent as ContactUsActiveSVG } from "assets/Mobile/icons/contact-us-active.svg";
import Deposit_Red from "assets/Mobile/icons/deposit_red.gif";
import HomeIcon from "assets/Mobile/icons/homeIconTab.png";
import { PATH } from "contants/path";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { selectIsAuthenticated } from "store/auth";
import { setOpenModal } from "store/modal";
import { theme } from "styles/theme";

import { BottomTabsStyle } from "./index.style";
const arrayTabs = [
  {
    label: "Home",
    link: PATH.Home,
    icon: (color: string) => (
      <img className="item-tab-home" src={HomeIcon} alt="" />
    ),
    iconActive: () => <img className="item-tab-home" src={HomeIcon} alt="" />,
    classNameIcon: "icon-tab-home",
    className: "item-tab-home",
  },
  {
    label: "Contact Us",
    link: PATH.contactUs,
    icon: () => <ContactUsSVG />,
    iconActive: () => <ContactUsActiveSVG />,
    className: "item-tab-sponsor",
  },

  {
    label: "Brand",
    link: PATH.Brand,
    icon: (color: string) => <BrandSVG fill={color} />,
    iconActive: () => <BrandActiveSVG />,
  },

  {
    label: "Home",
    link: PATH.Home,
    icon: (color: string) => <HomeSVG fill={color} />,
    iconActive: () => <HomeActiveSvg />,
  },
  {
    label: "Promotion",
    link: PATH.Promotion,
    icon: (color: string) => <PromosSvg fill={color} />,
    iconActive: () => <PromosActiveSvg />,
  },
  {
    label: "Deposit",
    link: PATH.Deposit,
    icon: (color: string) => (
      <img className="icon-deposit" src={Deposit_Red} alt="" />
    ),
    iconActive: () => <img className="icon-deposit" src={Deposit_Red} alt="" />,
    classNameIcon: "icon-tab-home",
    className: "item-tab-home",
  },

  {
    label: "Affiliate",
    link: PATH.User.Referral,
    icon: (color: string) => <AffiliateSVG fill={color} />,
    iconActive: () => <AffiliateActiveSvg />,
  },
  {
    label: "Profile",
    link: PATH.User.profile,
    includes: PATH.User.profile,
    icon: (color: string) => <ProfileSvg fill={color} />,
    iconActive: () => <ProfileActiveSvg />,
  },
];
const BottomTabs = () => {
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const { t } = useTranslation(["bottomtab"]);
  return (
    <div css={BottomTabsStyle.container}>
      <div css={BottomTabsStyle.lisTabs}>
        {arrayTabs.slice(3).map((item, index) => {
          const isActive = item.includes
            ? !pathname.localeCompare(item.includes)
            : pathname === item.link;

          const checkIsAuthenticated =
            isAuthenticated ||
            !item.includes?.includes("home") ||
            !item.includes?.includes("promotion");

          return (
            <div css={BottomTabsStyle.itemTab} key={`item-bottom-tab-${index}`}>
              <Link
                className={item.className}
                css={BottomTabsStyle.itemLink}
                to={item.link}
                onClick={(e) => {
                  if (checkIsAuthenticated) return;
                  e.preventDefault();
                  dispatch(setOpenModal("signin"));
                }}
              >
                <div
                  className={item.classNameIcon}
                  css={BottomTabsStyle.itemIcon}
                >
                  {isActive
                    ? item.iconActive()
                    : item.icon(theme.colors.gray[100])}
                  <span
                    css={BottomTabsStyle.itemLabel(
                      isActive ? theme.colors.red : undefined
                    )}
                  >
                    {t(item.label)}
                  </span>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BottomTabs;
