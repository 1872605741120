import Cock from "assets/Mobile/icons/cock.png";
import CockActive from "assets/Mobile/icons/cockActive.png";
import Fire from "assets/Mobile/icons/fire.gif";
import FishingIconME from "assets/Mobile/icons/fish_Empty.png";
import FishingIconM from "assets/Mobile/icons/fishing.png";
import LiveIconM from "assets/Mobile/icons/live.png";
import LiveIconME from "assets/Mobile/icons/live_Empty.png";
import LotteryIconM from "assets/Mobile/icons/lottery.png";
import LotteryIconME from "assets/Mobile/icons/lottery_Empty.png";
import SlotIconM from "assets/Mobile/icons/slot.png";
import SlotIconME from "assets/Mobile/icons/slot_Empty.png";
import SportIconM from "assets/Mobile/icons/sport.png";
import SportIconME from "assets/Mobile/icons/sport_Empty.png";
import BgFishingMobile from "assets/temporary/images/bg_fishingSM.jpg";
import BgLiveMobile from "assets/temporary/images/bg_liveSM.jpg";
import BgLotteryMobile from "assets/temporary/images/bg_lotterySM.jpg";
import BgSLotMobile from "assets/temporary/images/bg_slotsSM.jpg";
import BgSportMobile from "assets/temporary/images/bg_sportSM.jpg";
import BgFishingDesktop from "assets/temporary/images/bn_fishingXL.jpg";
import BgLiveDesktop from "assets/temporary/images/bn_liveXL.jpg";
import BgLotteryDesktop from "assets/temporary/images/bn_lotteryXL.jpg";
import BgSLotDesktop from "assets/temporary/images/bn_slotXL.jpg";
import BgSportDesktop from "assets/temporary/images/bn_sportXL.jpg";

export interface CategoryGameType {
  id: string;
  name: string;
  icon: string;
  iconSelected: string;
  path: string;
  backgroundMobile?: string;
  backgroundDesktop?: string;
  disableMobile?: boolean;
}

export const LIST_CATEGORY_GAME: CategoryGameType[] = [
  {
    id: "H",
    name: "Home",
    icon: "",
    iconSelected: "",
    path: "/",
    backgroundDesktop: "",
    backgroundMobile: "",
    disableMobile: true,
  },
  {
    id: "LC",
    name: "Live",
    iconSelected: LiveIconM,
    path: "/live",
    icon: LiveIconME,
    backgroundMobile: BgLiveMobile,
    backgroundDesktop: BgLiveDesktop,
  },
  {
    id: "SL",
    name: "Slots",
    iconSelected: SlotIconM,
    path: "/slots",
    icon: SlotIconME,
    backgroundMobile: BgSLotMobile,
    backgroundDesktop: BgSLotDesktop,
  },
  {
    id: "SB",
    name: "Sports",
    icon: SportIconME,
    path: "/sports",
    iconSelected: SportIconM,
    backgroundMobile: BgSportMobile,
    backgroundDesktop: BgSportDesktop,
  },
  {
    id: "FH",
    name: "Fishing",
    icon: FishingIconME,
    path: "/fishing",
    iconSelected: FishingIconM,
    backgroundMobile: BgFishingMobile,
    backgroundDesktop: BgFishingDesktop,
  },
  {
    id: "LT",
    name: "Lottery 4D",
    icon: LotteryIconME,
    path: "/lottery",
    iconSelected: LotteryIconM,
    backgroundMobile: BgLotteryMobile,
    backgroundDesktop: BgLotteryDesktop,
  },
  {
    id: "CF",
    name: "Cook Fighting",
    icon: LotteryIconME,
    path: "/cook-fighting",
    iconSelected: LotteryIconM,
    backgroundMobile: BgLotteryMobile,
    backgroundDesktop: BgLotteryDesktop,
  },
];

export const listCategoryMobile: CategoryGameType[] = [
  {
    id: "HT",
    name: "HotGame",
    icon: Fire,
    path: "/hot",
    iconSelected: Fire,
  },
  {
    id: "SL",
    name: "Slots",
    iconSelected: SlotIconM,
    path: "/slots",
    icon: SlotIconME,
    backgroundMobile: BgSLotMobile,
    backgroundDesktop: BgSLotDesktop,
  },
  {
    id: "LC",
    name: "Live",
    iconSelected: LiveIconM,
    path: "/live",
    icon: LiveIconME,
    backgroundMobile: BgLiveMobile,
    backgroundDesktop: BgLiveDesktop,
  },
  {
    id: "SB",
    name: "Sports",
    icon: SportIconME,
    path: "/sports",
    iconSelected: SportIconM,
    backgroundMobile: BgSportMobile,
    backgroundDesktop: BgSportDesktop,
  },
  {
    id: "FH",
    name: "Fishing",
    icon: FishingIconME,
    path: "/fishing",
    iconSelected: FishingIconM,
    backgroundMobile: BgFishingMobile,
    backgroundDesktop: BgFishingDesktop,
  },
  {
    id: "LT",
    name: "Lottery",
    icon: LotteryIconME,
    path: "/lottery",
    iconSelected: LotteryIconM,
    backgroundMobile: BgLotteryMobile,
    backgroundDesktop: BgLotteryDesktop,
  },
  {
    id: "CF",
    name: "Cock",
    icon: Cock,
    path: "/cock",
    iconSelected: CockActive,
  },
];
