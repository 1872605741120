/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, Modal, notification } from "antd";
import { PlayerBankMapping } from "api/bank/type";
import CloseIcon from "assets/temporary/icons/Close.png";
import { ReactComponent as RightIcon } from "assets/temporary/icons/right_icon.svg";
import InputCustom from "components/Common/InputCustom";
import { ResponseCode } from "contants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { selectUsername } from "store/auth";
import { addPlayerBank } from "store/bank/bank.thunk";
import { selectProfile } from "store/user";
import { getWithdrawalForm } from "store/withdraw/withdraw.thunk";
import { StatusNotification } from "types";
import { BankType } from "types/bank";
import * as yup from "yup";

import { BankMappingStyle, BankStyle } from "./index.style";
import { ModalSelectBank } from "./ModalSelectBank";
type Props = {
  visible: boolean;
  onClose: () => void;
};

const initBank: BankType = {
  bank_name: "",
  bank_id: "",
  picture: "",
};
const ModalBankMapping = (props: Props) => {
  const { visible, onClose } = props;

  const profile = useAppSelector(selectProfile);

  const schema = yup.object({
    account_no: yup.string().required("This field is required"),
    account_name: yup
      .string()
      .required("This field is required")
      .test(
        "accountName",
        "Bank account name must match the full name",
        (account_name: string) => {
          if (account_name.toLowerCase() === profile?.full_name.toLowerCase())
            return true;
          return false;
        }
      ),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<PlayerBankMapping>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      account_name: profile?.full_name,
    },
  });

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (status: StatusNotification, message: string) => {
    api[status]({
      message,
      description: "",
    });
  };
  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername);

  const [openSelectBank, setOpenSelectBank] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bank, setBank] = useState<BankType>(initBank);

  useEffect(() => {
    if (visible) return;
    reset();
    setBank(initBank);
  }, [visible]);

  useEffect(() => {
    if (!profile?.full_name) return;
    setValue("account_name", profile?.full_name);
  }, [profile]);

  const onSubmit: SubmitHandler<PlayerBankMapping> = async (data) => {
    setIsLoading(true);

    const res = await dispatch(
      addPlayerBank({ ...data, username, id: bank.bank_id })
    );
    setIsLoading(false);

    const { msg, status } = res.payload as any;
    if (status !== ResponseCode.SUCCESS) {
      openNotification("error", msg);
      return;
    }

    dispatch(getWithdrawalForm(username));
    openNotification("success", "Add bank player success!!!");
    onClose();
  };
  return (
    <Modal
      centered
      open={visible}
      closable={false}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
      css={BankStyle.modalContainer}
    >
      <div css={BankStyle.container}>
        <div css={BankStyle.header}>
          <div css={css({ width: 24, height: 24 })} />
          <span>{"Bank Mapping"}</span>
          <Button onClick={onClose} css={BankStyle.buttonClose}>
            <img src={CloseIcon} alt="" />
          </Button>
        </div>
        <div css={[BankStyle.content, BankMappingStyle.content]}>
          <Form
            className="deposit_form"
            onSubmitCapture={handleSubmit(onSubmit)}
          >
            <div css={BankStyle.bank} onClick={() => setOpenSelectBank(true)}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p>{bank.bank_name || "Select bank"}</p>
                <div>
                  <RightIcon />
                </div>
              </div>
            </div>

            <div>
              <InputCustom
                control={control}
                name="account_name"
                placeholder="Bank account name"
                value={profile?.full_name}
                disabled
              />
            </div>

            <div>
              <InputCustom
                control={control}
                name="account_no"
                errors={errors.account_no?.message}
                validate_status={errors.account_no && "error"}
                margin_bottom="12px"
                placeholder="Bank account number"
              />
            </div>

            <div>
              <Button
                disabled={!bank.bank_id}
                loading={isLoading}
                css={BankMappingStyle.button}
                htmlType="submit"
              >
                Add bank
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <ModalSelectBank
        visible={openSelectBank}
        onClose={() => setOpenSelectBank(false)}
        currencyId={bank.bank_id}
        onSelectItem={(val) => {
          setBank(val);
          setOpenSelectBank(false);
        }}
        setBank={setBank}
      />
      {contextHolder}
    </Modal>
  );
};

export default ModalBankMapping;
