/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Form } from "antd";
import { ValidateStatus } from "antd/es/form/FormItem";
import { ReactNode } from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import PhoneInput from "react-phone-number-input";

import { InputPhoneNumberStyle } from "./index.style";
interface SelectCustomProps {
  name: string;
  control: Control<FieldValues | any>;
  error?: string;
  validate_status?: ValidateStatus;
  value?: string;
  disabled?: boolean;
  margin_bottom?: string;
  prefix?: ReactNode;
}

const InputPhoneNumber = (props: SelectCustomProps) => {
  const { control, error, validate_status, margin_bottom, prefix } = props;
  return (
    <div>
      <Form.Item
        css={[
          { marginBottom: margin_bottom ? margin_bottom : "0px" },
          InputPhoneNumberStyle.self,
        ]}
        help={error}
        validateStatus={validate_status}
      >
        <div css={InputPhoneNumberStyle.inputContainer}>
          <Controller
            name={props.name}
            control={control}
            render={({ field }) => {
              return (
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    width: 100%;
                  `}
                >
                  <PhoneInput
                    {...field}
                    countries={["MY"]}
                    defaultCountry={"MY"}
                    limitMaxLength={true}
                    international
                    countryCallingCodeEditable={false}
                    id={props.name}
                    disabled={props.disabled}
                    css={InputPhoneNumberStyle.inputPhoneNumber}
                    numberInputProps={{
                      placeholder: "Phone Number",
                    }}
                  />
                  {prefix}
                </div>
              );
            }}
          />
        </div>
      </Form.Item>
    </div>
  );
};

export default InputPhoneNumber;
