/** @jsxImportSource @emotion/react */

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, notification } from "antd";
import { LoginFormType } from "api/auth/type";
import backgroundLogin from "assets/temporary/images/list_bg_2.jpg";
import InputCustom from "components/Common/InputCustom";
import InputPassword from "components/Common/InputPassword";
import { ModalCustom } from "components/Common/ModalCustom";
import { REGEX_PASSWORD, REGEX_USERNAME } from "contants/regex";
import { ResponseCode } from "contants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { selectIsLoading } from "store/auth";
import { signInWithCredentials } from "store/auth/auth.thunk";
import { selectOpenModal, setOpenModal } from "store/modal";
import { StatusNotification } from "types";
import { ApiResponse } from "types/api";
import * as yup from "yup";

import { LogoAuth } from "../LogoAuth";
import { SigninModalStyle } from "./index.style";

export const SigninModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["login", "placeholder", "validate"]);

  const openModal = useAppSelector(selectOpenModal);
  const isLoading = useAppSelector(selectIsLoading);

  const schema = yup
    .object({
      username: yup
        .string()
        .trim()
        .matches(REGEX_USERNAME, t("validate:UsernameValid"))
        .required(),
      password: yup
        .string()
        .trim()
        .matches(REGEX_PASSWORD, t("validate:PasswordValid"))
        .required(t("validate:PasswordValid")),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<LoginFormType>({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (status: StatusNotification, message: string) => {
    api[status]({
      message,
      description: "",
    });
  };
  const onSubmit: SubmitHandler<LoginFormType> = async (
    data: LoginFormType
  ) => {
    const res = await dispatch(
      signInWithCredentials({
        ...data,
        username: data.username.toLocaleLowerCase(),
      })
    );

    const resData = res.payload as ApiResponse;

    if (resData.status !== ResponseCode.SUCCESS) {
      api.destroy();
      openNotification("error", resData + "");
      return;
    }

    dispatch(setOpenModal(""));
  };

  const handleCloseModal = () => {
    dispatch(setOpenModal(""));
  };

  return (
    <ModalCustom
      isOpenModal={openModal.includes("signin")}
      handleCloseModal={handleCloseModal}
      titleModal={t("Login")}
      headerBackground={backgroundLogin}
      heightBackground={75}
      bodyModal={
        <>
          <LogoAuth />

          <Form
            css={SigninModalStyle.formContainer}
            onSubmitCapture={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <InputCustom
              disabled={isLoading}
              placeholder={t("placeholder:Username")}
              name={"username"}
              control={control}
              margin_bottom="8px"
              errors={errors.username?.message}
              validate_status={errors.username ? "error" : "success"}
              maxLength={16}
            />
            <InputPassword
              disabled={isLoading}
              placeholder={t("placeholder:Password")}
              name={"password"}
              control={control}
              margin_bottom="8px"
              error={errors.password?.message}
              validate_status={errors.password ? "error" : "success"}
              maxLength={15}
            />

            <span
              onClick={() => dispatch(setOpenModal("recoveryPassword"))}
              css={SigninModalStyle.forgetPass}
            >
              {t("Forget")}
            </span>

            <Button
              disabled={!isValid || isLoading}
              loading={isLoading}
              css={[
                SigninModalStyle.buttonSubmit,
                isValid && SigninModalStyle.buttonActive,
              ]}
              htmlType="submit"
            >
              {t("Login")}
            </Button>
            <span css={SigninModalStyle.register}>
              {t("CreateAcc")}{" "}
              <span onClick={() => dispatch(setOpenModal("signup"))}>
                {t("REGISTER")}
              </span>
            </span>
          </Form>
          {contextHolder}
        </>
      }
    />
  );
};
