import loadable from "@loadable/component";
import App from "App";
import { PATH } from "contants/path";
import DefaultLayout from "layouts/Desktop/DefaultLayout";
import UserLayout from "layouts/Desktop/UserLayout";
import { RouteObject } from "react-router-dom";

import ProtectedRoute from "./protected";

const CategoryGameNew = loadable(
  () => import("pages/Desktop/Game/page/CategoryGameNew")
);
const InfoVipPage = loadable(() => import("pages/Common/InfoVip"));
const MemberDocumentsPage = loadable(
  () => import("pages/Common/MemberDocuments")
);
const MemberVipPage = loadable(() => import("pages/Common/MemberVip"));
const NotFoundPage = loadable(() => import("pages/Common/NotFound"));
const TermsAndConditions = loadable(
  () => import("pages/Common/TermsAndConditions")
);
const PersonalDetails = loadable(() => import("pages/Desktop/PersonalDetails"));
const HomePage = loadable(() => import("pages/Desktop/HomePage"));
const DownloadPage = loadable(() => import("pages/Desktop/DownloadPage"));
const DepositPage = loadable(() => import("pages/Desktop/DepositPage"));
const WithdrawPage = loadable(() => import("pages/Desktop/WithdrawPage"));
const BrandPage = loadable(() => import("pages/Common/Brand"));
const PromotionPage = loadable(() => import("pages/Common/PromotionPage"));
const TransactionHistory = loadable(
  () => import("pages/Common/TransactionHistory")
);
const Bettingrecord = loadable(() => import("pages/Common/BettingRecord"));
const Settings = loadable(() => import("pages/Common/Settings"));
const Announcement = loadable(() => import("pages/Common/Announcement"));
// const CategoryGamePage = loadable(
//   () => import("pages/Desktop/Game/page/CategoryGamePage")
// );
const SubGamePage = loadable(
  () => import("pages/Desktop/Game/page/SubGamePage")
);
const Referral = loadable(() => import("pages/Desktop/Referral"));
const Contact = loadable(() => import("pages/Common/Contact"));

export const desktopRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        element: <DefaultLayout />,
        children: [
          {
            path: "/",
            element: <HomePage />,
          },
          {
            path: "*",
            element: <NotFoundPage />,
          },
          {
            path: PATH.Download,
            element: <DownloadPage />,
          },
          {
            path: PATH.Promotion,
            element: <PromotionPage />,
          },
          {
            path: PATH.Brand,
            element: <BrandPage />,
          },
          {
            path: PATH.CategoryGame,
            element: <CategoryGameNew />,
          },
          {
            path: PATH.SubGame,
            element: <SubGamePage />,
          },
          {
            path: PATH.contactUs,
            element: <Contact />,
          },
          {
            path: PATH.infoVip,
            element: <InfoVipPage />,
          },
        ],
      },
      {
        element: <ProtectedRoute />,
        children: [
          {
            element: <UserLayout />,
            children: [
              {
                path: PATH.User.personalDetails,
                element: <PersonalDetails />,
              },
              {
                path: PATH.User.TransactionHistory,
                element: <TransactionHistory />,
              },
              {
                path: PATH.User.deposit,
                element: <DepositPage />,
              },
              {
                path: PATH.User.withdraw,
                element: <WithdrawPage />,
              },
              {
                path: PATH.User.Setting,
                element: <Settings />,
              },
              {
                path: PATH.User.Announcement,
                element: <Announcement />,
              },
              {
                path: PATH.User.myVip,
                element: <MemberVipPage />,
              },
              {
                path: PATH.User.BettingRecord,
                element: <Bettingrecord />,
              },
              {
                path: PATH.TermsAndConditions,
                element: <TermsAndConditions />,
              },
              {
                path: PATH.User.Referral,
                element: <Referral />,
              },
              {
                path: PATH.User.memberDocuments,
                element: <MemberDocumentsPage />,
              },
              {
                path: PATH.User.MemberReport,
                element: <div>Member Report</div>,
              },
            ],
          },
        ],
      },
    ],
  },
];
