/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { ReactComponent as DropDownIcon } from "assets/Common/icons/chevron-down-solid.svg";
import iPlayLogo from "assets/temporary/icons/iplay_logo 2.png";
import { ItemLanguageType, languageOptions } from "contants/list-language";
import i18next from "i18next";
import { find } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "styles/theme";

import LanguageModal from "../LanguagePage";
import { LogoAuthStyle } from "./index.style";

export const LogoAuth = () => {
  const { t } = useTranslation("login");

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const itemLanguage = find(
    languageOptions,
    (item: ItemLanguageType) => i18next.language === item.value
  );
  return (
    <>
      <div css={LogoAuthStyle.logoApp}>
        <img src={iPlayLogo} alt="" />
        <span>{t("WelcomIplay")}</span>
        <div
          css={css`
            position: absolute;
            right: 18%;
          `}
        >
          <div
            onClick={() => setIsOpenModal(true)}
            css={css`
              padding: 4px 6px;
              background: linear-gradient(180deg, #fafafa 0%, #f4e7e8 100%);
              margin-left: 12px;
              border-radius: 32px;
              cursor: pointer;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;

              .icon {
                width: 20px;
                height: 20px;
                border-radius: 20px;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
              .dropdownIcon {
                width: 14px;
                height: 14px;
                margin-left: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                  fill: ${theme.colors.gray[200]};
                }
              }
            `}
          >
            <div className="icon">
              <img src={itemLanguage?.picture} alt="" />
            </div>
            <div className="dropdownIcon">
              <DropDownIcon />
            </div>
          </div>
        </div>
      </div>

      <LanguageModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      />
    </>
  );
};
