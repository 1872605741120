import { css } from "@emotion/react";

export const LayoutStyle = {
  root: css``,
  main: (isHeader: boolean) => css`
    height: 100vh;
    padding-bottom: 3rem !important;
    max-width: 1024px;
    margin: auto;
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  `,
};
