/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Image, Modal } from "antd";
import { withdrawApi } from "api/withdraw";
import { WithdrawalSubMissionFormMapping } from "api/withdraw/type";
import BankCard from "assets/temporary/icons/bank_card.png";
import { ReactComponent as CheckedIcon } from "assets/temporary/icons/checked.svg";
import CloseIcon from "assets/temporary/icons/Close.png";
import { ReactComponent as RightIcon } from "assets/temporary/icons/right_icon.svg";
import { ReactComponent as WalletIcon } from "assets/temporary/icons/wallet.svg";
import InputCustom from "components/Common/InputCustom";
import { ResponseCode } from "contants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useVerify } from "hooks/useVerify";
import _isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-router-dom";
import { selectUsername } from "store/auth";
import { selectPlayerBanks } from "store/bank";
import { getListPlayerBank } from "store/bank/bank.thunk";
import { selectOpenModal, setOpenModal } from "store/modal";
import { resetWithdrawalOrders } from "store/orders";
import { selectProfile } from "store/user";
import {
  initialState,
  selectDataWithdraw,
  selectIsLoadingWithdraw,
} from "store/withdraw";
import { getWithdrawalForm } from "store/withdraw/withdraw.thunk";
import { media } from "styles/breakpoints";
import { StatusNotification } from "types";
import { PlayerBankType } from "types/bank";
import * as yup from "yup";

import ModalBankMapping from "../Bank/ModalBankMapping";
import { ModalCurrency } from "../ModalDeposit/ModalCurrency";
import { ModalTransaction } from "../ModalDeposit/ModalTransaction";
import { ModalWithdrawStyle } from "./index.style";
interface Props {
  openNotification: (status: StatusNotification, message: string) => void;
}

export const ModalWithdraw = (props: Props) => {
  const { openNotification } = props;

  useVerify();

  const dispatch = useAppDispatch();
  const dataWithdraw = useAppSelector(selectDataWithdraw);

  const playerBank = useAppSelector(selectPlayerBanks);
  const username = useAppSelector(selectUsername);
  const profile = useAppSelector(selectProfile);
  const openModal = useAppSelector(selectOpenModal);
  const isLoading = useAppSelector(selectIsLoadingWithdraw);

  const isDataDepositEmpty = _isEqual(
    initialState.dataWithdrawal,
    dataWithdraw
  );

  const [isOpenModalCurrencies, setIsOpenModalCurrencies] =
    useState<boolean>(false);
  const [isOpenModalTransaction, setIsModalTransction] =
    useState<boolean>(false);
  const [openForm, setOpenForm] = useState<boolean>(false);

  const [amount, setAmount] = useState<number | null>(null);
  const [currencyId, setCurrencyId] = useState<string>("1");
  const [currencyName, setCurrencyName] = useState<string>("");
  const [detailPlayerBank, setDetailPlayerBank] = useState<PlayerBankType>({
    player_bank_id: "",
    bank_id: "",
    bank_name: "",
    bank_account_name: "",
    bank_account_no: "",
    bank_account_address: "",
  });

  const schema = () =>
    yup.object({
      amount: yup
        .number()
        .max(
          dataWithdraw.setting.max_withdrawal,
          `Max withdraw amount is ${dataWithdraw.setting.max_withdrawal}`
        )
        .min(
          dataWithdraw.setting.min_withdrawal,
          `Min withdraw amount is ${dataWithdraw.setting.min_withdrawal}`
        )
        .transform((value) => (Number.isNaN(value) ? null : value))
        .required("This field is required"),
    });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<WithdrawalSubMissionFormMapping>({
    mode: "onChange",
    resolver: yupResolver(schema()),
  });

  useEffect(() => {
    if (!username) return;
    if (!isDataDepositEmpty) return;

    dispatch(getWithdrawalForm(username));
    dispatch(getListPlayerBank(username));
  }, [username]);

  const onSubmitContinue = (data: { amount: number }) => {
    setAmount(data.amount);
    setIsModalTransction(true);
  };

  const handleSubmitWithdraw = async () => {
    if (!amount) return;

    const response = await withdrawApi.withdrawalSubmission(username, {
      currency_id: Number(currencyId),
      username,
      player_bank_id: Number(detailPlayerBank.player_bank_id),
      amount,
    });

    const { status } = response.data;

    if (status !== ResponseCode.SUCCESS) {
      openNotification("error", "Withdrawal failed!!!");
      return;
    }

    openNotification("success", "Withdrawal success!!!");
    reset();
    dispatch(setOpenModal(""));

    dispatch(resetWithdrawalOrders());
  };

  const handleClose = () => {
    reset();
    dispatch(setOpenModal(""));

    setAmount(null);
  };

  return (
    <>
      <Modal
        open={openModal.includes("withdraw")}
        onCancel={handleClose}
        centered
        footer={null}
        css={ModalWithdrawStyle.index}
        closable={false}
      >
        <div>
          <div css={ModalWithdrawStyle.backgroundHeader}>
            <div css={ModalWithdrawStyle.header}>
              <p css={ModalWithdrawStyle.title}>Withdraw</p>
              <Button
                onClick={handleClose}
                css={ModalWithdrawStyle.buttonClose}
              >
                <img src={CloseIcon} alt="" />
              </Button>
            </div>

            <div
              css={css`
                width: 416px;
                position: relative;
                z-index: 99;
                margin: 25px auto;

                ${media.sm} {
                  width: 100%;
                }
              `}
            >
              <div
                css={css`
                  transition: all 0.2s ease;
                  height: ${errors.amount?.message ? "200px" : "180px"};
                  width: 100%;
                  background-color: white;
                  margin-bottom: 25px;
                  border-radius: 10px;
                  padding: 18px;
                `}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <WalletIcon height={24} width={24} />
                    <div
                      style={{
                        marginLeft: "4px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          paddingTop: "4px",
                        }}
                      >
                        Current balance
                      </span>
                    </div>
                  </div>

                  <span
                    style={{
                      paddingTop: "4px",
                      fontWeight: 700,
                    }}
                  >
                    {profile?.points || "0.00"} MYR
                  </span>
                </div>

                <div>
                  <Form
                    className="deposit_form"
                    onSubmitCapture={handleSubmit(onSubmitContinue)}
                  >
                    <div
                      style={{
                        marginTop: "20px",
                        display: "grid",
                        gridTemplateColumns: "repeat(5, 1fr)",
                        gap: 8,
                      }}
                    >
                      <div
                        style={{
                          gridColumn: "1 / 5",
                        }}
                      >
                        <InputCustom
                          control={control}
                          name="amount"
                          errors={errors.amount?.message}
                          validate_status={errors.amount && "error"}
                          margin_bottom={"4px"}
                          placeholder={`Amout MIN: ${dataWithdraw.setting.min_withdrawal} / MAX: ${dataWithdraw.setting.max_withdrawal}`}
                        />
                        <p
                          css={css`
                            font-size: 12px;
                            color: #6c6c6c;
                          `}
                        >{`* Daily Withdrawal Times: ${dataWithdraw.setting?.daily_withdrawal_times}`}</p>
                        <p
                          css={css`
                            font-size: 12px;
                            color: #6c6c6c;
                          `}
                        >{`* Daily Withdrawal Limit: ${dataWithdraw.setting.daily_withdrawal_limit}`}</p>
                        <p
                          css={css`
                            font-size: 12px;
                            color: ${dataWithdraw.turnover.current >
                            dataWithdraw.turnover.target
                              ? "green"
                              : "red"};
                          `}
                        >{`* Turnover: ${dataWithdraw.turnover.current} / ${dataWithdraw.turnover.target}`}</p>
                      </div>

                      <div
                        css={ModalWithdrawStyle.currency}
                        onClick={() => setIsOpenModalCurrencies(true)}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <p>
                            {currencyName ||
                              (dataWithdraw.currencies.length > 0 &&
                                dataWithdraw.currencies[0].currency_name)}
                          </p>
                          <div>
                            <RightIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        margin-top: 40px;
                      `}
                    >
                      <p
                        css={css`
                          margin-bottom: "8px";
                          font-weight: 700;
                          font-size: 15px;
                        `}
                      >
                        Choose account bank
                      </p>

                      <div
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        {playerBank.map((player: PlayerBankType) => (
                          <div
                            css={ModalWithdrawStyle.selectBank}
                            key={player.player_bank_id}
                            style={{
                              borderRadius: "10px",
                              borderBottom: "1px solid #F5F5F5",
                              marginBottom: "4px",
                            }}
                            onClick={() => {
                              setDetailPlayerBank(player);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              <p
                                css={css`
                                  font-weight: 500;
                                  font-size: 15px;
                                `}
                              >
                                {player.bank_name}
                              </p>
                              <p
                                css={css`
                                  color: #95959d;
                                  font-size: 12px;
                                `}
                              >
                                {player.bank_account_no}
                              </p>
                            </div>
                            {player.player_bank_id ===
                              detailPlayerBank.player_bank_id && (
                              <CheckedIcon />
                            )}
                          </div>
                        ))}

                        <div
                          css={ModalWithdrawStyle.selectBank}
                          style={{
                            borderRadius: "10px",
                            borderBottom: "1px solid #F5F5F5",
                            marginBottom: "4px",
                            cursor: "pointer",
                          }}
                          onClick={() => setOpenForm(true)}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            <div
                              css={css`
                                margin-right: 8px;
                              `}
                            >
                              <Image
                                src={BankCard}
                                style={{
                                  height: "24px",
                                  width: "24px",
                                }}
                                preview={false}
                              />
                            </div>
                            <p
                              css={css`
                                font-weight: 400;
                                font-size: 15px;
                                padding-top: 4px;
                              `}
                            >
                              Add more bank
                            </p>
                          </div>
                          <RightIcon height={24} width={24} />
                        </div>
                      </div>
                    </div>

                    <Button
                      css={ModalWithdrawStyle.button}
                      htmlType="submit"
                      disabled={!detailPlayerBank.player_bank_id}
                      style={{
                        border: "transparent",
                        color: "white",
                        pointerEvents: detailPlayerBank.player_bank_id
                          ? "unset"
                          : "none",
                      }}
                      loading={isLoading}
                    >
                      Continue
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div css={ModalWithdrawStyle.backgroundBottom(playerBank.length)} />
        </div>
      </Modal>

      <ModalCurrency
        currencies={dataWithdraw.currencies}
        currencyId={currencyId}
        isOpenModalCurrencies={isOpenModalCurrencies}
        setCurrencyId={setCurrencyId}
        setCurrencyName={setCurrencyName}
        setIsOpenModalCurrencies={setIsOpenModalCurrencies}
      />

      <ModalTransaction
        isOpenModalTransaction={isOpenModalTransaction}
        setIsModalTransction={setIsModalTransction}
        isWithdraw
        amount={amount}
        detailPlayerBank={detailPlayerBank}
        onSubmit={handleSubmitWithdraw}
      />

      <ModalBankMapping visible={openForm} onClose={() => setOpenForm(false)} />
    </>
  );
};
