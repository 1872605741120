import { css, Theme } from "@emotion/react";
import BackgroundWithdraw from "assets/temporary/images/banner_deposit.png";

export const ModalWithdrawStyle = {
  index: () => css`
    width: 480px !important;

    .ant-modal-content {
      padding: 0;
      background-color: #f5f5f5;
      border-radius: 10px;
      height: 100%;

      .ant-modal-close-x {
        svg {
          color: white;
          height: 24px;
          width: 24px;
        }
      }
    }
  `,
  backgroundHeader: () => css`
    background-image: url(${BackgroundWithdraw});
    height: 150px;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  `,
  backgroundBottom: (lengthBank: number) => css`
    background-color: #f5f5f5;
    height: calc(392px + (${lengthBank} - 1) * 80px);
    width: 100%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  `,

  title: () => css`
    color: white;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    flex-grow: 1;
    margin-left: 46px;
  `,

  button: ({ colors }: Theme) => css`
    width: 100%;
    height: 44px;
    border-radius: 8px;
    background-color: ${colors.red};
    margin-bottom: 28px;

    :hover {
      transform: scale(1.05);
      background-color: ${colors.red};
      border: ${colors.red};
      color: black !important;
    }
  `,

  currency: () => css`
    grid-column: 5 / 12;
    height: 44px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #1c1c1e;
    font-weight: 500;
    cursor: pointer;
  `,

  depositType: () => css`
    height: 44px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #636369;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 20px;
  `,

  selectBank: () => css`
    padding: 16px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    cursor: pointer;
  `,
  header: () => css`
    display: flex;
    padding-top: 20px;
  `,
  buttonClose: () => css`
    width: 24px;
    height: 24px;
    border: 0px;
    background-color: transparent;
    display: flex;
    align-items: center;
    margin-right: 22px;
    z-index: 9999;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
      z-index: 9999;
    }
  `,
};
