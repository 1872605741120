import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enJson from "./translation/en.json";
import msJson from "./translation/ms.json";
import zhJson from "./translation/zh.json";

export const resources = {
  en: enJson,
  ms: msJson,
  zh: zhJson,
} as const;

// i18n.use(initReactI18next).init({
//   fallbackLng: "en",
//   debug: false,
//   resources: resources,
// });
// export { i18n };
i18n.use(initReactI18next).init({
  fallbackLng: "en",
  debug: false,
  resources: {
    en: enJson,
    ms: msJson,
    zh: zhJson,
  },
});

export { i18n };
