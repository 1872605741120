import { css, Theme } from "@emotion/react";
import { CategoryGameType } from "contants/menu-game";
import { theme } from "styles/theme";

const isActive = (item: CategoryGameType, pathName: string) => {
  if (item.name === "Home" && pathName === "/") return true;

  return item.path !== "/" && pathName.includes(item.path);
};

export const HeaderStyle = {
  index: (backgroundColor: string) => css`
    width: 100%;
    min-width: 1200px;
    height: 75px;
    position: fixed;
    background-color: ${backgroundColor};
    transition: all 0.3s ease;
    z-index: 100;

    &:hover {
      .item-category {
        color: #83696a;
      }
    }
  `,
  container: () => css`
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
  `,

  wrapper: () => css`
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  listCategory: () => css`
    display: flex;
    align-items: center;
  `,

  categoryItem: (
    item: CategoryGameType,
    pathname: string,
    colorText: string
  ) => css`
    padding: 0 6px 2px;
    margin: 2px;
    font-weight: 500;
    color: ${isActive(item, pathname) ? "rgb(189, 22, 29)" : colorText};
    font-size: 14px;
    transition: all 0.15s ease;
    border-bottom: ${isActive(item, pathname)
      ? "2px solid rgb(189, 22, 29)"
      : "2px solid transparent"};
    cursor: pointer;

    &:hover {
      border-bottom: 3px solid rgb(189, 22, 29);
      color: rgb(189, 22, 29);
    }
  `,

  avatar: () => css`
    width: 50px;
    height: 50px;
    cursor: pointer;
  `,

  menuItem: (colorText: string) => css`
    display: flex;
    flex-direction: column;
    padding: 0 6px;
    cursor: pointer;
    font-size: 14px;
    color: ${colorText};

    span {
      text-align: center;
    }
  `,

  menuItemIcon: () => css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  infoHeader: () => css`
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    flex-direction: column;

    h4 {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      /* color: #83696a; */
    }
  `,

  buttonRegister: ({ colors }: Theme) => css`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    border-radius: 14px;
    margin-left: 12px;
    border: transparent;
    background: ${colors.gradient[2]};
    height: 28px;
    width: 80px;

    span {
      font-family: "SVN-Gilroy";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.24px;
      color: #83696a;
    }
  `,
  buttonLogin: ({ colors }: Theme) => css`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    border: 1px solid rgba(149, 149, 157, 0.3);
    border-radius: 14px;
    height: 28px;
    background: ${colors.gradient[1]};

    span {
      font-family: "SVN-Gilroy";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.24px;
      color: ${theme.colors.white};
    }
  `,
  rows: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  `,
  language: css`
    padding: 4px 6px;
    background: linear-gradient(180deg, #fafafa 0%, #f4e7e8 100%);
    margin-left: 12px;
    border-radius: 32px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .icon {
      width: 20px;
      height: 20px;
      border-radius: 20px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .dropdownIcon {
      width: 14px;
      height: 14px;
      margin-left: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: ${theme.colors.gray[200]};
      }
    }
  `,
};
