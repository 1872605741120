/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button, Image } from "antd";
import { SubGameResponse } from "api/game/type";
import { ReactComponent as DropDownIcon } from "assets/Common/icons/chevron-down-solid.svg";
import { ReactComponent as CurrencyIcon } from "assets/Common/icons/currency.svg";
import iPlayLogo from "assets/Common/icons/iplay_logo 2.png";
import avatar from "assets/temporary/images/avatar_default.png";
import Loading from "components/Common/Loading";
import { headerMenu, HeaderMenuStyle } from "contants/headerMenu";
import { ItemLanguageType, languageOptions } from "contants/list-language";
import { CategoryGameType, LIST_CATEGORY_GAME } from "contants/menu-game";
import { PATH } from "contants/path";
import { GameTypeEnum, SubGameEnum } from "enums/game";
import { formatNumberWithCommas } from "helpers/formatNumber";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { find } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnnouncement } from "store/announcement/announcement.thunk";
import { selectIsAuthenticated, selectUsername } from "store/auth";
import { updateGameProviderCode } from "store/game";
import { getSubGameList } from "store/game/game.thunk";
import { ModalType, setOpenModal } from "store/modal";
import { selectProfile, selectProfileLoading } from "store/user";
import { getMemberBalance } from "store/user/user.thunk";

import DropdownGameProvider from "./DropdownGameProvider";
import { HeaderStyle } from "./index.style";

const isShow = (isAuthenticated: boolean, name: string) => {
  if (name.includes("Promos") || name.includes("Download")) return true;

  if (isAuthenticated) return true;

  return false;
};

export const Header = () => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { pathname = "" } = useLocation();
  const { i18n } = useTranslation();
  const { t } = useTranslation(["header", "currency"]);
  const itemLanguage = find(
    languageOptions,
    (item: ItemLanguageType) => i18n.language === item.value
  );

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isLoading = useAppSelector(selectProfileLoading);
  const profileUser = useAppSelector(selectProfile);
  const profile = useAppSelector(selectProfile);
  const username = useAppSelector(selectUsername);

  const [visibleMenu, setVisibleMenu] = useState<string>("");
  const [backgroundColor, setBackgroundColor] = useState<
    "white" | "transparent"
  >("transparent");
  const [colorText, setColorText] = useState<string>("white");

  useEffect(() => {
    if (!username) return;
    dispatch(getMemberBalance({ username }));
    dispatch(getAnnouncement({ page: 1, size: 100, type: 2 }));
  }, [username]);

  useEffect(() => {
    handleChangeColor();

    setBackgroundColor("transparent");

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pathname]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event?.target as Node;
      if (!menuRef?.current?.contains(target)) {
        setVisibleMenu("");
      }
    };
    if (visibleMenu) {
      document.addEventListener("mouseover", handleClickOutside);
    } else {
      document.removeEventListener("mouseover", handleClickOutside);
    }
    return () => document.removeEventListener("mouseover", handleClickOutside);
  }, [visibleMenu]);

  const handleChangeColor = () => {
    if (
      (!pathname.includes("user") &&
        !pathname.includes("promotion") &&
        !pathname.includes("terms-and-conditions")) ||
      pathname === "/"
    ) {
      setColorText("#83696a");
    } else {
      setColorText("white");
    }
  };

  const handleScroll = () => {
    if (window.scrollY < 15) {
      setBackgroundColor("transparent");
      handleChangeColor();
      return;
    }
    setColorText("#83696a");
    setBackgroundColor("white");
  };

  const handleActionMenu = (item: HeaderMenuStyle) => {
    if (
      item.link === PATH.Promotion ||
      item.link === PATH.User.Referral ||
      item.link === PATH.Download ||
      item.link === PATH.User.myVip
    ) {
      navigate(item.link);
      return;
    }
    dispatch(setOpenModal(item.link as ModalType));
  };

  const getGameLobby = async (
    idGame: string,
    path: string,
    providerCode?: string
  ) => {
    const respone = await dispatch(
      getSubGameList({
        game_type: idGame,
        lobby: SubGameEnum.Lobby,
      })
    );

    const { data } = respone.payload as SubGameResponse;

    if (data.length > 0) {
      dispatch(
        updateGameProviderCode(
          providerCode ? providerCode : data[0].provider_code
        )
      );
      navigate(
        `${path}/${providerCode ? providerCode : data[0].provider_code}`
      );
    }
  };

  const handleRedirectSubGame = (
    idGame: string,
    path: string,
    providerCode?: string
  ) => {
    if (
      idGame.includes(GameTypeEnum.Slot) ||
      idGame.includes(GameTypeEnum.Fishing) ||
      idGame.includes(GameTypeEnum.Lottery)
    ) {
      setVisibleMenu("");
      return getGameLobby(idGame, path, providerCode);
    }

    navigate(path);
  };

  return (
    <div ref={menuRef} css={HeaderStyle.index(backgroundColor)}>
      <div css={HeaderStyle.container}>
        <div css={HeaderStyle.wrapper}>
          <div
            css={css`
              height: 60px;
              cursor: pointer;
              width: 69px;
            `}
            onClick={() => navigate("/")}
          >
            <Image alt="img" src={iPlayLogo} preview={false} />
          </div>

          <div css={HeaderStyle.listCategory}>
            {LIST_CATEGORY_GAME.map((item: CategoryGameType, index: number) => (
              <div key={item.id}>
                <div
                  css={HeaderStyle.categoryItem(item, pathname, colorText)}
                  onClick={() => {
                    handleRedirectSubGame(item.id, item.path);
                  }}
                  onMouseEnter={(e) => {
                    if (item.id !== "H") {
                      setVisibleMenu(item.id);
                    } else {
                      setVisibleMenu("");
                    }
                  }}
                  className={visibleMenu && "item-category"}
                >
                  {t(item.name)}
                </div>
                {item.id !== "H" && (
                  <DropdownGameProvider
                    key={`component-cate-dropdown-${index}`}
                    idCategoryGame={item.id}
                    visible={visibleMenu === item.id}
                    handleRedirectSubGame={handleRedirectSubGame}
                  />
                )}
              </div>
            ))}
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {headerMenu.map((item: HeaderMenuStyle) => {
              if (!isShow(isAuthenticated, item.label))
                return <div key={item.label} />;

              return (
                <div
                  key={item.label}
                  css={HeaderStyle.menuItem(colorText)}
                  onClick={() => handleActionMenu(item)}
                >
                  <div css={HeaderStyle.menuItemIcon}>
                    <item.icon fill="rgb(189, 22, 29" />
                  </div>
                  <span className={visibleMenu && "item-category"}>
                    {t(item.label)}
                  </span>
                </div>
              );
            })}
          </div>

          <div css={HeaderStyle.rows}>
            {isLoading ? (
              <Loading height={40} center width={140} fontSize={24} />
            ) : (
              <>
                {isAuthenticated ? (
                  <div
                    css={css`
                      display: flex;
                    `}
                  >
                    <div css={HeaderStyle.infoHeader}>
                      <h4
                        css={css`
                          width: 100%;
                          text-align: right;
                          color: ${visibleMenu ? "#83696a" : colorText};
                        `}
                      >
                        {profileUser && profileUser.username}
                      </h4>

                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                        `}
                      >
                        <CurrencyIcon height={24} width={24} />
                        <span
                          css={css`
                            font-size: 13px;
                            margin-left: 4px;
                            width: 500;
                            color: ${visibleMenu ? "#2f0506" : colorText};
                          `}
                        >
                          {t("currency:MYR")}{" "}
                          {formatNumberWithCommas(profile?.points || "0.00")}
                        </span>
                      </div>
                    </div>
                    <img
                      src={avatar}
                      alt=""
                      css={HeaderStyle.avatar}
                      onClick={() => navigate(PATH.User.TransactionHistory)}
                    />
                  </div>
                ) : (
                  <div
                    css={css`
                      display: flex;
                    `}
                  >
                    <Button
                      onClick={() => dispatch(setOpenModal("signin"))}
                      css={HeaderStyle.buttonLogin}
                    >
                      {t("Log In")}
                    </Button>
                    <Button
                      onClick={() => dispatch(setOpenModal("signup"))}
                      css={HeaderStyle.buttonRegister}
                    >
                      {t("Join Now")}
                    </Button>
                  </div>
                )}
              </>
            )}
            <div
              onClick={() => dispatch(setOpenModal("language"))}
              css={HeaderStyle.language}
            >
              <div className="icon">
                <img src={itemLanguage?.picture} alt="" />
              </div>
              <div className="dropdownIcon">
                <DropDownIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
