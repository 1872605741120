export const userPath = "/user";

export const PATH = {
  Home: "/",
  Bank: "/bank",
  Deposit: "/deposit",
  Brand: "/brand",
  Withdraw: "/withdraw",
  Orders: "/orders",
  CategoryGame: "/:categoryName",
  SubGame: "/:categoryName/:providerId",
  TermsAndConditions: "/terms-and-conditions",
  Promotion: "/promotion",
  Download: "/download",
  liveGamePage: "/live",
  slotGamePage: "/slots",
  sportGamePage: "/sports",
  fishingGamePage: "/fishing",
  lotteryGamePage: "/lottery",
  contactUs: "/contact-us",
  infoVip: "/program-vip",
  User: {
    profile: userPath,
    personalDetails: userPath + "/details",
    TransactionHistory: userPath + "/transaction-history",
    Setting: userPath + "/setting",
    deposit: userPath + "/deposit",
    withdraw: userPath + "/withdraw",
    Announcement: userPath + "/messages",
    BettingRecord: userPath + "/betting-record",
    MemberReport: userPath + "/member-report",
    UpdateInfo: userPath + "/update-info",
    changePassword: userPath + "/change-password",
    Referral: userPath + "/referral",
    memberDocuments: userPath + "/documents",
    myVip: userPath + "/vip",
  },
};
