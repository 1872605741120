// Live

import JDBFishing from "assets/Desktop/images/game/fishing/jdb-fishing.png";
import JILIFishing from "assets/Desktop/images/game/fishing/jili-fishing.png";
import SpadeGamingFishing from "assets/Desktop/images/game/fishing/spadegaming-fishing.png";
import BigGaming from "assets/Desktop/images/game/live-casio/big-gaming.png";
import DreamGaming from "assets/Desktop/images/game/live-casio/dream-gaming.png";
import evolutionGaming from "assets/Desktop/images/game/live-casio/evolutionGaming.png";
import imageBG from "assets/Desktop/images/game/live-casio/live_banner_bg.png";
import imageDG from "assets/Desktop/images/game/live-casio/live_banner_DG.png";
import imageEVO from "assets/Desktop/images/game/live-casio/live_banner_Evo.png";
import imagePT from "assets/Desktop/images/game/live-casio/live_banner_PT.png";
import imageSA from "assets/Desktop/images/game/live-casio/live_banner_SA.png";
import imageSX from "assets/Desktop/images/game/live-casio/live_banner_SB.png";
import IconSX from "assets/Desktop/images/game/live-casio/live_icon_baccarat.png";
import IconBG from "assets/Desktop/images/game/live-casio/live_icon_bg.png";
import IconDG from "assets/Desktop/images/game/live-casio/live_icon_dg.png";
import IconEVO from "assets/Desktop/images/game/live-casio/live_icon_Evo.png";
import IconPT from "assets/Desktop/images/game/live-casio/live_icon_pt.png";
import IconSA from "assets/Desktop/images/game/live-casio/live_icon_sa.png";
import IconSXSelected from "assets/Desktop/images/game/live-casio/live_icon_selected_baccarat.png";
import IconDGSelected from "assets/Desktop/images/game/live-casio/live_icon_selected_dg.png";
import IconPTSelected from "assets/Desktop/images/game/live-casio/live_icon_selected_pt.png";
import IconSASelected from "assets/Desktop/images/game/live-casio/live_icon_selected_sa.png";
import IconBGSelected from "assets/Desktop/images/game/live-casio/live_icon_selected-bg.png";
import IconEVOSelected from "assets/Desktop/images/game/live-casio/live_icon_selected-evo.png";
import titleSX from "assets/Desktop/images/game/live-casio/live_title_baccarat.png";
import titleBG from "assets/Desktop/images/game/live-casio/live_title_bg.png";
import titleDG from "assets/Desktop/images/game/live-casio/live_title_DG.png";
import titleEVO from "assets/Desktop/images/game/live-casio/live_title_Evo.png";
import titlePT from "assets/Desktop/images/game/live-casio/live_title_PT.png";
import titleSA from "assets/Desktop/images/game/live-casio/live_title_SA.png";
import Playtech from "assets/Desktop/images/game/live-casio/playtech.png";
import SaGaming from "assets/Desktop/images/game/live-casio/sa-gaming.png";
import SexyBaccarat from "assets/Desktop/images/game/live-casio/sexy-baccarat.png";
// import ABSGAMINGLoterry from "assets/Desktop/images/game/lottery/abs-gaming.png";
import GrandDragon from "assets/Desktop/images/game/lottery/grand-dragon-lottery.png";
// import QQGAMINGLoterry from "assets/Desktop/images/game/lottery/qq-gaming.png";
import Slot918KISS from "assets/Desktop/images/game/slots/918-slots.png";
import HabaneroSlots from "assets/Desktop/images/game/slots/habanero-slots.png";
import JiliSlots from "assets/Desktop/images/game/slots/jili-slots.png";
import JokerSlots from "assets/Desktop/images/game/slots/joker-slots.png";
import PlaytechSlots from "assets/Desktop/images/game/slots/playtech-slots.png";
import PragmaticPlay from "assets/Desktop/images/game/slots/pragmatic-play-slots.png";
import CQ9GAMINGSlots from "assets/Desktop/images/game/slots/qq9-gaming-slots.png";
import RedTigerSlots from "assets/Desktop/images/game/slots/red-tiger-slots.png";
import BtiSport from "assets/Desktop/images/game/sports/bti-sport.png";
import M8BetSport from "assets/Desktop/images/game/sports/m8-bet-sport.png";
import SabaSport from "assets/Desktop/images/game/sports/saba-sport.png";
import imageBTI from "assets/Desktop/images/game/sports/sports_banner_BTI.png";
import imageMS from "assets/Desktop/images/game/sports/sports_banner_ms.png";
import imageSaba from "assets/Desktop/images/game/sports/sports_banner_saba.png";
import imageSV from "assets/Desktop/images/game/sports/sports_banner_sv.png";
import IconBTI from "assets/Desktop/images/game/sports/sports_icon_bti.png";
import IconMS from "assets/Desktop/images/game/sports/sports_icon_ms.png";
import IconSaba from "assets/Desktop/images/game/sports/sports_icon_saba.png";
import IconSelectedBTI from "assets/Desktop/images/game/sports/sports_icon_selected_bti.png";
import IconSelectedMS from "assets/Desktop/images/game/sports/sports_icon_selected_ms.png";
import IconSelectedSaba from "assets/Desktop/images/game/sports/sports_icon_selected_saba.png";
import IconSelectedSV from "assets/Desktop/images/game/sports/sports_icon_selected_sv.png";
import IconSV from "assets/Desktop/images/game/sports/sports_icon_sv.png";
import titleBTI from "assets/Desktop/images/game/sports/sports_title_BTI.png";
import titleMS from "assets/Desktop/images/game/sports/sports_title_ms.png";
import titleSaba from "assets/Desktop/images/game/sports/sports_title_saba.png";
import titleSV from "assets/Desktop/images/game/sports/sports_title_sv.png";
import Sv388Sport from "assets/Desktop/images/game/sports/sv388-sport.png";
export interface SymbolsType {
  icon: string;
  iconSelected: string;
  providerCode: string;
}

export interface DataDetailCategoryType {
  providerCode: string;
  titleImage: string;
  description: string;
  image: string;
  providerName: string;
  providerImage?: string;
}

export interface ListDataType {
  id: string;
  name: string;
  symbols: SymbolsType[];
  data: DataDetailCategoryType[];
  path: string;
}

export const LIST_DATA: ListDataType[] = [
  {
    id: "LC",
    name: "Live",
    path: "/live",
    symbols: [
      {
        icon: IconEVO,
        iconSelected: IconEVOSelected,
        providerCode: "EVO",
      },
      {
        icon: IconBG,
        iconSelected: IconBGSelected,
        providerCode: "BG",
      },
      {
        icon: IconSX,
        iconSelected: IconSXSelected,
        providerCode: "SX",
      },
      {
        icon: IconSA,
        iconSelected: IconSASelected,
        providerCode: "SA",
      },
      {
        icon: IconDG,
        iconSelected: IconDGSelected,
        providerCode: "DG",
      },
      {
        icon: IconPT,
        iconSelected: IconPTSelected,
        providerCode: "PT",
      },
    ],
    data: [
      {
        providerCode: "EVO",
        titleImage: titleEVO,
        description: "categoryGame:contentEvolution",
        image: imageEVO,
        providerName: "categoryGame:Evolution",
        providerImage: evolutionGaming,
      },
      {
        providerCode: "BG",
        titleImage: titleBG,
        description: "categoryGame:contentBig",
        image: imageBG,
        providerName: "categoryGame:Big",
        providerImage: BigGaming,
      },
      {
        providerCode: "SX",
        titleImage: titleSX,
        description: "categoryGame:contentSexy",
        image: imageSX,
        providerName: "categoryGame:Sexy",
        providerImage: SexyBaccarat,
      },
      {
        providerCode: "SA",
        titleImage: titleSA,
        description: "categoryGame:contentSA",
        image: imageSA,
        providerName: "categoryGame:SA",
        providerImage: SaGaming,
      },
      {
        providerCode: "DG",
        titleImage: titleDG,
        description: "categoryGame:contentDream",
        image: imageDG,
        providerName: "categoryGame:Dream",
        providerImage: DreamGaming,
      },
      {
        providerCode: "PT",
        titleImage: titlePT,
        description: "categoryGame:contentPlaytech",
        image: imagePT,
        providerName: "categoryGame:Playtech",
        providerImage: Playtech,
      },
    ],
  },
  {
    id: "SB",
    path: "/sports",
    name: "Sports",
    symbols: [
      {
        icon: IconSaba,
        iconSelected: IconSelectedSaba,
        providerCode: "IBC",
      },
      {
        icon: IconBTI,
        iconSelected: IconSelectedBTI,
        providerCode: "BTI",
      },
      {
        icon: IconMS,
        iconSelected: IconSelectedMS,
        providerCode: "M8",
      },
    ],
    data: [
      {
        providerCode: "IBC",
        titleImage: titleSaba,
        description: "categoryGame:contentSABA",
        image: imageSaba,
        providerImage: SabaSport,
        providerName: "categoryGame:SABA",
      },
      {
        providerCode: "BTI",
        titleImage: titleBTI,
        description: "categoryGame:contentBTI",
        image: imageBTI,
        providerImage: BtiSport,
        providerName: "categoryGame:BTI",
      },
      {
        providerCode: "M8",
        titleImage: titleMS,
        description: "categoryGame:contentM8",
        image: imageMS,
        providerImage: M8BetSport,
        providerName: "categoryGame:M8",
      },
    ],
  },
  {
    id: "SL",
    name: "Slots",
    path: "/slots",
    symbols: [],
    data: [
      {
        providerCode: "PP",
        titleImage: titleSaba,
        description: "",
        image: PragmaticPlay,
        providerImage: PragmaticPlay,
        providerName: "Pragmatic Play",
      },
      {
        providerCode: "SXJL",
        titleImage: titleBTI,
        description: "",
        image: JiliSlots,
        providerImage: JiliSlots,
        providerName: "JILI",
      },
      {
        providerCode: "PT",
        titleImage: titleMS,
        description: "",
        image: PlaytechSlots,
        providerImage: PlaytechSlots,
        providerName: "Playtech",
      },
      {
        providerCode: "HB",
        titleImage: titleSV,
        providerImage: HabaneroSlots,
        description: "",
        image: HabaneroSlots,
        providerName: "Habanero",
      },
      {
        providerCode: "SXRT",
        titleImage: titleSV,
        providerImage: RedTigerSlots,
        description: "",
        image: RedTigerSlots,
        providerName: "Red Tiger",
      },
      {
        providerCode: "CQ9",
        titleImage: titleSV,
        providerImage: CQ9GAMINGSlots,
        description: "",
        image: CQ9GAMINGSlots,
        providerName: "CQ9 GAMING",
      },
      {
        providerCode: "918",
        titleImage: titleSV,
        providerImage: Slot918KISS,
        description: "",
        image: Slot918KISS,
        providerName: "918 KISS",
      },
      {
        providerCode: "JK",
        titleImage: titleSV,
        providerImage: JokerSlots,
        description: "",
        image: JokerSlots,
        providerName: "Joker",
      },
    ],
  },
  {
    id: "FH",
    name: "Fishing",
    path: "/fishing",
    symbols: [],
    data: [
      {
        providerCode: "SG",
        titleImage: titleSaba,
        description: "",
        image: SpadeGamingFishing,
        providerImage: SpadeGamingFishing,
        providerName: "Spade Gaming",
      },
      {
        providerCode: "SXJL",
        titleImage: titleBTI,
        description: "",
        image: JILIFishing,
        providerImage: JILIFishing,
        providerName: "JILI",
      },
      {
        providerCode: "SXJD",
        titleImage: titleMS,
        description: "",
        image: JDBFishing,
        providerImage: JDBFishing,
        providerName: "JDB",
      },
    ],
  },
  {
    id: "LT",
    name: "Lottery",
    path: "/lottery",
    symbols: [],
    data: [
      // {
      //   providerCode: "ABS",
      //   titleImage: titleSaba,
      //   description: "",
      //   image: ABSGAMINGLoterry,
      //   providerImage: ABSGAMINGLoterry,
      //   providerName: "ABS GAMING",
      // },
      // {
      //   providerCode: "QQ",
      //   titleImage: titleBTI,
      //   description: "",
      //   image: QQGAMINGLoterry,
      //   providerImage: QQGAMINGLoterry,
      //   providerName: "QQ GAMING",
      // },
      {
        providerCode: "GDL",
        titleImage: titleBTI,
        description: "",
        image: GrandDragon,
        providerImage: GrandDragon,
        providerName: "Grand Dragon",
      },
    ],
  },
  {
    id: "CF",
    name: "Cook Fighting",
    path: "/cook-fighting",
    symbols: [
      {
        icon: IconSV,
        iconSelected: IconSelectedSV,
        providerCode: "SXSV",
      },
    ],
    data: [
      {
        providerCode: "SXSV",
        titleImage: titleSV,
        providerImage: Sv388Sport,
        description: "categoryGame:SXSV",
        image: imageSV,
        providerName: "categoryGame:contentSXSV",
      },
    ],
  },
];
