import { css, Theme } from "@emotion/react";
import { theme } from "styles/theme";

export const InputPhoneNumberStyle = {
  self: ({ colors }: Theme) => css`
    &.PhoneInputInput,
    .PhoneInputInput {
      font-size: 14px;
      color: #000000;
      font-weight: 500;
      padding-top: 4px;
    }
  `,

  inputContainer: () => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* padding-right: 12px; */
    background: ${theme.colors.white};
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  `,
  inputPhoneNumber: () => css`
    flex: 1;
    height: 44px;
    padding-left: 12px;

    .PhoneInputInput {
      height: 44px;
      border: 0px;
      outline: none;
      padding-left: 16px;
    }

    .PhoneInputCountrySelectArrow {
      margin-left: 8px !important;
    }
  `,
};
