/** @jsxImportSource @emotion/react */
import BottomTabs from "components/Mobile/ui/BottomTabs";
import SideBarLeft from "components/Mobile/ui/SideBarLeft";
import { PATH } from "contants/path";
import { Outlet, useLocation } from "react-router-dom";

import { LayoutStyle } from "./index.style";
const DefaultLayout = () => {
  const { pathname } = useLocation();
  const isHeader =
    pathname === PATH.Promotion ||
    pathname === PATH.Brand ||
    pathname === PATH.contactUs;
  return (
    <div css={LayoutStyle.root}>
      <SideBarLeft />
      <main css={LayoutStyle.main(isHeader)}>
        <Outlet />
      </main>
      <BottomTabs />
    </div>
  );
};

export default DefaultLayout;
