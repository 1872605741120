import { css } from "@emotion/react";

export const BackToTopStyle = {
  self: css`
    position: fixed;
    bottom: 62px;
    right: 20.5px;
  `,

  btnBackToTop: css`
    width: 35px !important;
    height: 35px !important;
    border-radius: 50% !important;
    border: none;
    background: linear-gradient(to right, #bd161d, #f9222b);

    & .ant-wave {
      display: none;
    }
  `,
  backTitle: css`
    color: #83696a;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin-top: 6px;
  `,
};
