import { css } from "@emotion/react";

export const CheckboxCustomStyle = {
  container: css`
    .ant-form-item-explain {
      text-align: center;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: red;
      border-color: red;
    }
  `,
};
