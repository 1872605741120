/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button } from "antd";
import { ReactComponent as FacebookIcon } from "assets/Common/icons/facebook.svg";
import FclIcon from "assets/Common/icons/fcl.svg";
import FpxIcon from "assets/Common/icons/fpx.svg";
import IfmIcon from "assets/Common/icons/ifm.svg";
import { ReactComponent as InstagramIcon } from "assets/Common/icons/instagram.svg";
import PagcorIcon from "assets/Common/icons/pagcor.svg";
import { ReactComponent as PhoneIcon } from "assets/Common/icons/phone.svg";
import TetherIcon from "assets/Common/icons/tether.svg";
import { ReactComponent as YoutubeIcon } from "assets/Common/icons/youtube.svg";
import EeziePay from "assets/Desktop/icons/payment-getway/eeziepay.png";
import { PATH } from "contants/path";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { FooterStyle } from "./index.style";

const LOGO_LIST = [
  {
    name: "fpx-logo",
    src: FpxIcon,
  },
  {
    name: "tether-logo",
    src: TetherIcon,
  },
  {
    name: "pagcor-logo",
    src: PagcorIcon,
  },
  {
    name: "fcl-logo",
    src: FclIcon,
  },

  {
    name: "ifm-logo",
    src: IfmIcon,
  },
];

const PaymentGateway = [
  {
    name: "Eezie Pay",
    src: EeziePay,
  },
];

const SOCIAL_LIST = [
  {
    icon: <FacebookIcon />,
    link: "#",
  },
  {
    icon: <InstagramIcon />,
    link: "#",
  },
  {
    icon: <YoutubeIcon />,
    link: "#",
  },
  {
    icon: <PhoneIcon />,
    link: "#",
  },
];

const INFORMATION_LIST = [
  {
    label: "FAQ",
    link: "#",
  },
  {
    label: "Privacy Policy",
    link: "#",
  },
  {
    label: "Responsible Gaming",
    link: "#",
  },
  {
    label: "Terms and Conditions",
    link: PATH.TermsAndConditions,
  },
  {
    label: "Contact Us",
    link: PATH.contactUs,
  },
];

const Footer = () => {
  const navigate = useNavigate();

  const { t } = useTranslation("footer");

  return (
    <footer css={FooterStyle.self}>
      <div css={FooterStyle.footerTop}>
        <div css={FooterStyle.box}>
          <p css={FooterStyle.boxTitle}>{t("Payment Method")}</p>
          <div>
            {LOGO_LIST.slice(0, 2).map((item, index) => (
              <img
                key={index}
                src={item.src}
                alt={item.name}
                css={FooterStyle.logo}
              />
            ))}
          </div>
        </div>
        <div css={FooterStyle.box}>
          <p css={FooterStyle.boxTitle}>{t("Licenses")}</p>
          {LOGO_LIST.slice(2).map((item, index) => (
            <img
              key={index}
              src={item.src}
              alt={item.name}
              css={FooterStyle.logo}
            />
          ))}
        </div>
        <div css={FooterStyle.box}>
          <p css={FooterStyle.boxTitle}>{t("Payment Gateway")}</p>
          {PaymentGateway.map((item, index) => (
            <img
              key={index}
              src={item.src}
              alt={item.name}
              css={FooterStyle.logo}
            />
          ))}
        </div>
      </div>

      <div css={FooterStyle.footerBottom}>
        <p css={FooterStyle.desc}>{t("contentFooter")}</p>
        <div css={FooterStyle.socials}>
          {SOCIAL_LIST.map((item, index) => (
            <Button
              key={index}
              css={FooterStyle.socialBtn}
              size="small"
              icon={item.icon}
              href={item.link}
            />
          ))}
        </div>

        <ul
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 24px;
          `}
        >
          {INFORMATION_LIST.map((item, index: number) => (
            <li
              key={item.link + index}
              css={css`
                padding: 0 10px;
                text-decoration: none;
                list-style: none;
                color: rgba(255, 255, 255, 0.9);
                border-right: ${index !== INFORMATION_LIST.length - 1
                  ? "2px solid white"
                  : "none"};
                font-weight: 200;
                cursor: pointer;
                transition: all 0.3s ease;

                &:hover {
                  color: rgba(255, 255, 255, 0.6);
                }
              `}
              onClick={() => navigate(item.link)}
            >
              {t(item.label)}
            </li>
          ))}
        </ul>
        <p css={FooterStyle.signature}>{t("contentEnd")}</p>
      </div>
    </footer>
  );
};

export default Footer;
